@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
* {
  font-family: 'Quicksand', sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#posts-container {
  min-height: 100vh;
  width: 600px;
  margin-left: calc(50vw - 300px);
  border-left: 1px solid #eff3f4;
  border-right: 1px solid #eff3f4;
}

.post {
  padding: 15px 25px;
  border-bottom: 1px solid #eff3f4;
}

.post .user {
  display: inline-block;
  font-size: 16px;
  color: #212121;
  font-weight: 700;
  margin: 0;
}

.post.newpost .user {
  margin-bottom: 10px;
}

.post > .date {
  display: inline-block;
  margin: 0;
  margin-left: 10px;
  color: #777;
  font-size: 14px;
}

.post > .title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-wrap: anywhere;
  word-break: break-all;  
}

.post > .content {
  font-size: 16px;
  line-height: 125%;
  overflow-wrap: break-word;
  word-break: break-word;  
  overflow-y: hidden;
  padding-bottom: 15px;
}

.post > .content.shorten {
  height: 1000px;
  position: relative;
  -webkit-mask-image: linear-gradient(180deg,#000 80%,transparent);
  mask-image: linear-gradient(180deg,#000 80%,transparent);
}
.post > .content.expand {
  -webkit-animation: expand-content 2s forwards;
          animation: expand-content 2s forwards;
}

@-webkit-keyframes expand-content {
  from {
    max-height: 1000px;
  }
  50% {
    max-height: 2000px;
  }
  to {
    max-height: 9999px;
  }
}

@keyframes expand-content {
  from {
    max-height: 1000px;
  }
  50% {
    max-height: 2000px;
  }
  to {
    max-height: 9999px;
  }
}

.post > .content ~ .expand {
  display: none;
}

.post > .content.shorten ~ .expand {
  display: inline-flex;
  padding: 0 25px;
  height: 30px;
  align-items: center;
  justify-content: center;
  width: 500px;
  cursor: pointer;
}
.post > .content.shorten ~ .expand:hover {
  text-decoration: underline;
}

.post > .content .image {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.post > .content img {
  max-width: 100%;
  max-height: 400px;
}

.post > .parent {
  font-size: 16px;
  color: #777;
  margin: 10px 0 15px 0;
}

a {
  color: #1d9bf0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.post code {
  display: inline-block;
  background: #f6f7f8;
  padding: 4px 8px;
  margin: 2px 0;
  font-weight: 500;
  line-height: 140%;
  font-style: normal;
  text-decoration: none;
}

.post-form {
  position: relative;
}

.post-form > .title, .post-form > .content {
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  width: calc(100% - 32px);
  outline: 0;
  font-size: 16px;
  resize: none;
}

.post-form > .title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.post-form > .content {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.post-form.comment > .content {
  border-top: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 12px;
  width: calc(100% - 26px);
}

.post-form > .submit, a.button {
  opacity: 0.5;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 1000px;
  border: 0;
  background-color: #1d9bf0;
  color: white;
  font-weight: 700;
  margin-top: 10px;
}

.post-form.comment > .submit {
  font-size: 14px;
  padding: 6px 12px;
  font-weight: 600;
}

.post-form > .submit.valid, a.button {
  transition: background-color 0.2s;
  cursor: pointer;
  opacity: 1;
}

.post-form > .submit.valid:hover, a.button:hover {
  background-color: #1a8cd8;
}

.editable-text .input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  outline: 0;
  display: none;
}

.editable-text.edit .input {
  display: block;
}

.editable-text.edit > .text {
  display: none;
}

.count {
  font-size: 12px;
  color: #777;
  position: absolute;
  right: 5px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  margin-top: -5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 1;
  transition: opacity 1s;
}

.count.hidden {
  opacity: 0;
}

.interact {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.interact > div {
  position: relative;
  display: inline-block;
  padding: 2px 5px;
  cursor: pointer;
  color: #536471;
}

.interact svg {
  height: 16px;
  width: 16px;
  vertical-align: middle;
  transition: color 0.2s;
}

.interact .comment {
  height: 14px;
  width: 14px;
  margin-left: 1px;
  margin-top: 1px;
}

.interact .link {
  height: 18px;
  width: 18px;
  margin-top: -1px;
  margin-left: -1px;
}

.interact > div::after {
  content: "";
  display: block;
  opacity: 0;
  transition: opacity 0.2s;
  height: 32px;
  width: 32px;
  position: absolute;
  top: -3px;
  left: -3px;
  border-radius: 9999px;
}

.interact > .likes::after {
  background: rgba(249, 24, 128, 0.15);
}

.interact > .comments::after {
  background: rgba(29, 155, 240, 0.15);
}

.interact > .share::after {
  background: rgba(0, 186, 124, 0.15);
}

.interact > div:hover::after {
  opacity: 1;
}

.interact .likes.liked, 
.interact .likes:hover
{
  color: #f91880;
}

.interact .comments:hover {
  color: #1d9cf0;
}

.interact .share:hover {
  color: #00ba7c;
}

.interact .int-count {
  font-weight: 600;
  margin-left: 10px;
  font-size: 14px;
  transition: color 0.2s;
}

.post > .comments {
  height: 0;
  overflow: hidden;
  transition: padding 0.25s, margin-top 0.25s;
}

.post > .comments.show {
  padding: 15px 0;
  margin-top: 15px;
  height: auto;
  border-top: 1px solid #eff3f4;
}

.post > .comments .comments-container {
  margin-top: 15px;
  border-left: 1px solid #ccc;
}

.post >.comments .post {
  padding-right: 0;
}

a.button {
  text-decoration: none;
}

h2.error {
  text-align: center;
}

#notifs-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}

#notifs-container .notif {
  padding: 10px 20px;
  border-radius: 4px;
  margin: 0 10px;
  -webkit-animation: show-notif 0.4s forwards;
          animation: show-notif 0.4s forwards;
}

#notifs-container .notif.hide {
  -webkit-animation: hide-notif 0.4s forwards;
          animation: hide-notif 0.4s forwards;
}

#notifs-container .notif.info {
  background: rgba(29, 156, 240, 0.75);
  color: white;
}

#notifs-container .notif.error {
  background: rgba(240, 29, 29, 0.75);
  color: white;
}

.notif .notif-body {
  margin: 0;
}

@-webkit-keyframes show-notif {
  from {
    -webkit-transform: translateY(calc(100% + 50px));
            transform: translateY(calc(100% + 50px));
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes show-notif {
  from {
    -webkit-transform: translateY(calc(100% + 50px));
            transform: translateY(calc(100% + 50px));
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes hide-notif {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(calc(100% + 50px));
            transform: translateY(calc(100% + 50px));
  }
}

@keyframes hide-notif {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(calc(100% + 50px));
            transform: translateY(calc(100% + 50px));
  }
}

#sidebar {
  position: fixed;
  top: 0;
  left: calc(50% - 600px);
  width: 250px;
  padding: 25px;
  display: flex;
  justify-content: center;
}

#sidebar a.button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  padding: 12px 16px;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
  color: black;
}

#sidebar a.button:hover {
  background: rgba(128,128,128,0.15);
}

#sidebar a.button svg, #sidebar a.button span  {
  vertical-align: middle;
}

#sidebar a.button svg {
  margin-right: 15px;
  height: 24px;
  width: 24px;
}

#sidebar a.button span {
  margin-right: 6px;
}
